.App {
  display: flex;
  text-align: center;
  background-color: #FEAA2C;
  width: 100vw;
  height:100vh;
  flex: 1;
  background-size: cover;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Header-font {
  font-weight: normal;
  font-family: serif;
  line-height: 0.89;
  text-transform: uppercase;
}

.Pearup-desktop {
  min-width: 100px;
  width: 9vw;
  margin-bottom: 5px;
  max-width: 500px;
  max-height: 100px;
}

.Pearup-screenshots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 8vh;
  background-color: #FEAA2C;
  object-fit: contain;
}

.Pearup-screenshots-image {
  min-width: 200px;
  width: 20vw;
  max-width: 300px;
  max-height: 1000px;
  padding: 1vw;
  padding-bottom: 8vh;

}

.Desktop {
  display: flex;
  text-align: center;
  width: 100vw;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Footer {
  position: fixed;
  background-color: #FEAA2C;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 15px;
}

.Footer-support {
  position: fixed;
  background-color: #FEAA2C;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Support {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 5px;
}

.Mobile-store-button {
  min-width: 100px;
  width: 10vw;
  max-width: 150px;
  padding: 10px;
}

.Pearup-support-icon {
  background-color: #FEAA2C;
  height: 50vw;
  width: 50vw;
  max-height: 270px;
  max-width: 270px;
  padding-top: 10vh;
  object-fit: contain;
}

.Support-email {
  background-color: #FEAA2C;
  width: 100vw;
  padding-bottom: 100px;
  color: #fff;
  padding-top: 5vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.Bottom-text {
  width: 100%;
  background-color: #FEAA2C;
}

.Header-bar {
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.Footer-bar {
  width: 100%;
  flex: 1;
  justify-content: center;
  background-color: #343a40;
}

.Footer-text {
  color: #fff;
  text-align: center;
}

.Header-bar-icon {
  width: 25px;
  margin-left: 10px;
  margin-bottom: 5px;
  object-fit: contain;
}

.Header-bar-icon-play-store {
  width: 40px;
  margin-left: 10px;
  object-fit: contain;
}

.Contact-image {
  width: 50vw;
  max-width: 500px;
  height: 50vh;
  max-height: 500px;
  margin-top: 50px;
  object-fit: contain;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
